
@import '@angular/material/prebuilt-themes/indigo-pink.css';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.pagination {
  padding: 20px 16px;
  float: right;
  text-align: center; /* Center-align the content */
  font-size: 18px;
}
zz
/* dashboard */

.dashboard-container {
  display: flex; /* Use flexbox for layout */
  height: 100vh; /* Make the container full viewport height */

  /* You can also set up additional styling for the dashboard-container, e.g., background color, padding, etc. */
}

.sidebar {
  height: 100%; /* Make the sidebar take the full height of its parent (dashboard-container) */
  /* Add any additional styling for your sidebar here */
  background-image: linear-gradient(180deg, #f8f9fc 10%, #c2cbe5 100%);

}
.body {
  height: 100%;
  width: 100%;
}
.dashboard-item {
  background-color: var(--bs-accordion-active-bg);
  border: #c2cbe5;
}




html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* registration css */

.font {
  font-family: apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
}
/* verify-otp */


/* login */
.custom-snackbar {
  z-index: 10000; /* Adjust the z-index value as needed */
  top:40px;
}

.mat-drawer-content {
  position: relative;
  z-index: 1;
  display: block;
  height: 100%;
  overflow:hidden !important;
}




html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
/* .container,container-fluid{
  position: relative;
  top:50px;
} */
